/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import CSSTransition from 'react-transition-group/CSSTransition';
import { useDispatch, useSelector } from 'react-redux';
import { getProductAttributeValue } from '../../../helpers/productAttributeHelper';
import getTransformedImageURLs from '../../../helpers/getTransformedImageURLs';
import getHighestValueAttribute from '../../../helpers/getHighestValueAttribute';
import TransparentLoader from '../../TransparentLoader';
import Price from '../../Price';
import BundlePrice from '../../BundlePrice';
import LoadImage from '../../LoadImage';
import Modal from '../../Modal';
import * as WEBSITES from '../../../constants/website';
import notificationTypes from '../../../constants/modalNotificationConst';
import { ASAP, ASAP_AND_SCHEDULE, SCHEDULE } from '../../../constants/deliveryType';
import AddToCartButton from '../../AddToCartButton';
import DiscountInPercent from '../../DiscountInPercent';
import getDefaultTab, { getMenuType } from '../../../helpers/getDefaultTab';
import { isWishlistApplicable } from '../../../constants/feature';
import getProductType from '../../../helpers/getProductType';
import FavouritesIcon from './FavouritesIcon';
import { increaseURLstack, popNotification, popStack, pushNotification, pushNotificationOverlap } from '../../../redux/slices/modalSlice';
import { setUrlBeforeProduct } from '../../../redux/slices/userSlice';
import { removeFromSaveForLaterAPI } from '../../../NetworkCall/apiCalls';
import { setIsSavedForLaterItemAddedOrRemoved, setSaveForLaterItemsLoading } from '../../../redux/slices/wishlistSlice';
import { useSegmentNew } from '../../../hooks/segment';
import { setPositionID } from '../../../redux/slices/otherSlice';

const ProductSkeleton = React.memo(({
  data,
  categorySlug,
  width50,
  className,
  showProductPage,
  hideProductProperties,
  hideProductWeight,
  showAnimation,
  addingToCart,
  // itemInCart,
  handleProductClick,
  productCounterType,
  children,
  page,
  from,
  fromPage,
  openInPopup = false,
  modalRef,
  productIndex,
  categoryIndex,
  scheduleType,
  categoryID,
  isMostPopularProduct,
  isSavedProduct = false,
  filtersforSegment = false,
  unified_menu,
  asapEnabled,
  scheduleEnabled,
  fromSpecialCategory,
  isFeaturedProduct,
  setShowLoader,
  showLoader
}) => {
  const router = useRouter();

  const dispatchRedux = useDispatch()
  const isLoggedIn = useSelector(state => state.user.isLoggedIn);
  const notifications = useSelector(state => state.modal.notifications);

  const [isRemoveIconDisabled, setIsRemoveIconDisabled] = useState(false);

  const {
    pathname,
    query: { tab = getDefaultTab() }
  } = router;

  const { trackEvent } = useSegmentNew();

  const [isASAP,] = useState(tab !== SCHEDULE, [tab]);
  const [menutype,] = useState(getMenuType(data?.menu));

  function openProductPopup(e) {
    e.preventDefault();
    e.stopPropagation();
    const { slug, showTag } = data;
    const { product_details_id: productDetailsId, bundle_id: bundleID } = data;
    const isBundle = !!data.bundle_id;
    if (modalRef) {
      modalRef.current.scrollTop = 0;
    }

    const pdpPopupOpen = notifications?.[0]?.type === "productDetails"

    if (pdpPopupOpen) {
      dispatchRedux(popNotification())
      dispatchRedux(popStack())

    }
    dispatchRedux(pushNotification({
      type: notificationTypes.productDetails,
      data: {
        slug,
        tab,
        page,
        isBundle,
        showTag,
        isMostPopularProduct
      }
    }))
    dispatchRedux(increaseURLstack())

    let position = `${productDetailsId}-${categorySlug}-${isASAP ? 'asap' : 'schedule'}${categoryID ? `===cat_${categoryID}` : ''
      }`;
    if (bundleID) {
      position = `bundle-${bundleID}-${categorySlug}-${isASAP ? 'asap' : 'schedule'}${categoryID ? `===cat_${categoryID}` : ''}`;
    }
    let url = `/product/${slug}?tab=${tab}`

    trackEvent(
      'Product Clicked',
      {
        event: 'Product Clicked',
        payload: {
          product_id: data.bundle_id ? data.bundle_id : data.master_product_id,
          sku: data.bundle_id ? data.bundle_id : data.master_product_id,
          category: data.full_shop_category_name,
          category_frontend: data.category_name,
          name: data.product_name || data.name,
          brand: data?.brand_names?.[0] || '',
          product_attributes: Array.isArray(data.product_attributes_second) ? data.product_attributes_second.join('/') : '',

          variant: `${data.product_weight} ${data.product_unit}`,
          price: data.newDiscountedPrice || data.product_price || data.price,
          original_price: data.price_without_deal,
          coupon: data.coupon,
          strain_type: data.strain_type,
          currency: data?.category_currency === '$' ? 'usd' : data?.category_currency || 'usd',
          position: data.productIndex,
          quantity: 1,
          url: `${window.location.origin}${url}`,
          image_url: data.product_image,
          best_seller: isMostPopularProduct,
          recommendation_type: data?.recommendation_type || '',
          page,
          from,
          ...(filtersforSegment ? { filterApplied: filtersforSegment } : {})
        }

      })

    dispatchRedux(setPositionID({ position }))
    !(pdpPopupOpen) && dispatchRedux(setUrlBeforeProduct({ urlBeforeProduct: `${window.location.pathname + window.location.search}` }))
    window.history.replaceState({ ...window.history.state, as: url, url }, '', url);
  }


  const {
    product_unit: productUnit,
    product_weight: productWeight,
    id,
    slug,
    product_details_id: productDetailsId,
    bundle_id: bundleID,
    is_sold_out: isSoldOut,
    product_image: productImageOriginal,
    product_attributes: productAttributes,
    domain_specific_attributes: domainSpecificAttributes,
    product_name: productName,
    product_sub_category: productSubCategory,
    price: productPrice,
    original_price: originalPrice,
    bundle_selling_price: bundleSellingPrice,
    limit_exceeded: limitExceeded,
    product_currency: productCurrency,
    product_strain_type_name: productStrain,
    is_deal_available: isDealAvailable,
    is_pre_deal_available: isPreDealAvailable,
    price_without_deal: priceWithoutDeal,
    deal_price_type: dealPriceType,
    brands,
    image_alt_tag: imageAltText,
    brand_names: brandsNames,
    // view_offer:viewOffer,
    offer_product: offerProduct,
    fresh_drop: freshDrop
  } = data;
  const [productDescription,] = useState({
    weight: productUnit === '1/8 oz' || productUnit === '1/2 oz' ? `${productUnit}` : `${productWeight} ${productUnit}`,
    type: getProductType(data),
    isSomeDealAvailable: isDealAvailable || isPreDealAvailable,
    bulkBasePrice: data?.bulk_base_price || false,
    orignalPrice: (isDealAvailable || isPreDealAvailable) ? priceWithoutDeal : (data?.bulk_base_price || false),
    outerID: `${id}_${productUnit}`,
    innerID: `${productDetailsId}-${categorySlug}-${isASAP ? 'asap' : 'schedule'}`,
    url: `/product/${slug}?tab=${menutype}`,
    isWishListPage: pathname ? pathname === '/wishlist/favorites' || pathname === '/wishlist/saved-for-later' : false,
    productAltText: imageAltText && imageAltText !== '' ? imageAltText : productName,
    productImage: getTransformedImageURLs(productImageOriginal).srcSmall
  });

  let { weight, type, orignalPrice, outerID, innerID, url, isWishListPage, productAltText, productImage } = productDescription;
  if (bundleID) {
    outerID = id;
    innerID = `bundle-${bundleID}-${categorySlug}-${isASAP ? 'asap' : 'schedule'}`;
    url = `/bundle/${slug}?tab=${menutype}`;
  }
  const viewOffers = () => {
    dispatchRedux(openInPopup ? pushNotificationOverlap({
      type: notificationTypes.viewOffers,
      data: {
        ...data,
        page,
        scheduleType
      }
    }) : pushNotification({
      type: notificationTypes.viewOffers,
      data: {
        ...data,
        page,
        scheduleType
      }
    }))
  };

  const removeSavedProduct = async () => {
    setIsRemoveIconDisabled(true);
    dispatchRedux(setSaveForLaterItemsLoading(true))
    let result = await removeFromSaveForLaterAPI({ item: data, isMostPopularProduct, fromPage, type: from, isLoggedIn },)
    if (result) {
      dispatchRedux(setIsSavedForLaterItemAddedOrRemoved(true))
      trackEvent(
        'Save for Later Product removed',
        {
          event: 'Save for Later Product removed',
          payload: {
            recommendation_type: data.recommendation_type,
            product_id: data?.bundle_id ? data.bundle_id : data.master_product_id,
            sku: data.bundle_id ? data.bundle_id : data.master_product_id,
            best_seller: isMostPopularProduct,
            best_seller_type: fromPage,
            type,
            is_featured_product: isFeaturedProduct,
            category_frontend: data.custom_category_name ? data.custom_category_name : data.category_name,
            category: data.full_shop_category_name || '',
            name: data.name || data.product_name,
            product_attributes: Array.isArray(data.product_attributes_second) ? data.product_attributes_second.join('/') : '',
            brand: Array.isArray(data.brand_names) ? data.brand_names.join('/') : data.brand_slug || '',
            variant: `${data.category_weight} ${data.category_unit}`,
            price: data.product_price || data.price,
            original_price: data.price_without_deal || data.price,
            strain_type: data.product_strain_type_name || '',
            position: 1,

          }
        }, true)
    }
    setIsRemoveIconDisabled(false);


  };

  const renderScheduleType = () => {
    // show tags only if location has both menu available to deliver
    if (type != ASAP_AND_SCHEDULE && asapEnabled && scheduleEnabled && !isSoldOut) {
      return (
        <div className="mt-2 d-flex align-items-center wishlistp-icons">
          <span className={`${type === ASAP ? 'icon-stopwatch' : 'icon-calendar-line'} mr-2`} />
          <span>{type === ASAP ? 'ASAP' : 'Scheduled'}</span>
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className={`latest-product ${bundleID ? 'text-left' : ''} ${width50 ? 'product-grid' : ''
        } relative ${className} lazy-load-product-height`}
      id={outerID}
    >
      <>
        <div className="relative inner height-100" id={innerID}>
          <>
            <div className="product-space-between-container">
              <div className="offer-tag">
                {data.promo_tag && data.promo_tag !== '' ? (
                  <div className="text-primary buy-more-text">
                    {data.promo_tag && data.promo_tag !== '' ? data.promo_tag : ' '}
                  </div>
                ) : null}
                {!!isDealAvailable && (
                  <div className="offer-holder">
                    <div className="sale">
                      <DiscountInPercent
                        productFinalPrice={productPrice}
                        priceWithoutDeal={priceWithoutDeal}
                        dealPriceType={dealPriceType}
                      />
                    </div>
                  </div>
                )}
                {freshDrop ? (
                  <div className="fresh-drop">
                    <span>NEW</span>
                  </div>
                ) : null}
              </div>
              {isSavedProduct && isWishlistApplicable ? (
                <div className="save-for-later-icon">
                  <button onClick={removeSavedProduct} disabled={isRemoveIconDisabled} className="btn">
                    <span className="icon-close-circle" />
                  </button>
                </div>
              ) : null}
              {isWishlistApplicable ? (
                <FavouritesIcon
                  data={data}
                  isSavedProduct={isSavedProduct}
                  isMostPopularProduct={isMostPopularProduct}
                  type={from}
                  fromPage={fromPage}
                />
              ) : null}
              <div className={`top-container ${data.promo_tag ? 'new-tag' : ''} `} id={`product-image-${data?.bundle_id ? data.bundle_id : data.master_product_id}`}>
                <a href={url} onClick={openInPopup ? openProductPopup : showProductPage} className="link-wrapper img-wrapper" title={url}>
                  <LoadImage
                    src={productImage}
                    alt={productAltText}
                    className={`img-fluid ${isSoldOut ? 'opacity-5' : ''} `}
                    width="200"
                    height="200"
                    avoidLazyLoad={!!fromSpecialCategory || categoryIndex === 0}
                  />
                </a>
                {/* <div className="strain-fix-container d-flex align-items-center justify-content-between">
                  <>
                    {hideProductProperties || bundleID ? null : (
                      <>
                        {productStrain && (
                          <div className={`strain-container text-uppercase ${productStrain}  d-inline-block `}>
                            {productStrain}
                          </div>
                        )}
                      </>
                    )}
                  </>
                  
                </div> */}
              </div>
              <div className="bottom-container pt-2">
                <div className="prod-details-wrap">
                  {process.env.NEXT_PUBLIC_APP !== WEBSITES.GRASSDOOR && unified_menu == 1 && data.showTag ? (
                    <div className="asap">
                      <i className="icon-fast-time " />
                      <span> ASAP</span>
                    </div>
                  ) : null}
                  <a href={url} onClick={openInPopup ? openProductPopup : showProductPage} className="product-name-weight">
                    <p className="bold mb-2 d-inline-block text-clamp mt-0">{productName}</p>
                  </a>
                  <div className="weight-brand-holder">
                    <div className="weight-product">
                      {hideProductWeight || bundleID ? null : (
                        <span className="weight-container m-0">
                          <span class="material-symbols-outlined icon">scale</span>
                          {weight}
                        </span>
                      )}
                    </div>
                    <>
                      {!!brandsNames && !!brandsNames.length && (
                        <Link className="brand-name md-grey-color" href={`/brands/${brands?.[0]}`}>
                          <a className="brand-name md-grey-color">
                            {brandsNames[0]}
                          </a>
                        </Link>
                      )}
                      {isWishListPage && isWishlistApplicable ? <>{renderScheduleType()}</> : null}
                    </>
                  </div>

                  <div className="strain-fix-container d-flex align-items-center mb-1">
                    <>
                      {hideProductProperties || bundleID ? null : (
                        <>
                          {productStrain && (
                            <div className={`${productStrain} strain-container`}>
                              <span class={`material-symbols-outlined d-none sativa icon`}>clear_day</span>
                              <span class={`material-symbols-outlined d-none indica icon`}>bedtime</span>
                              <span class={`material-symbols-outlined d-none hybrid icon`}>routine</span>
                              {productStrain}
                            </div>
                          )}
                        </>
                      )}
                    </>
                    {hideProductProperties || bundleID ? null : (
                      <>
                        {productAttributes && productAttributes.length ? (
                          <>
                            {getHighestValueAttribute(productAttributes, productWeight, productUnit)
                              .filter(item => item)
                              .map(item => {
                                return (
                                  <h6 key={`${productDetailsId}_${item.name}`} className="thc-cbd mb-0">
                                    <b>{getProductAttributeValue(item)}</b> {` ${item.name}`}
                                  </h6>
                                );
                              })}
                          </>
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
                <div className="price-offer-cart-wrapper d-flex align-items-center justify-content-between">
                  <div className="">
                    <div className="price-container">
                      {bundleID ? (
                        <BundlePrice
                          className="price"
                          currency={productCurrency}
                          orignalPrice={originalPrice}
                          price={bundleSellingPrice}
                        />
                      ) : (
                        <Price productDetails={data} orignalPrice={orignalPrice} />
                      )}
                    </div>
                    {
                      // viewOffer
                      offerProduct?.length && offerProduct.find(offer => offer.products.length > 0) ? (
                        <div className="xy-product-offer">
                          <a onClick={viewOffers}>
                            <span className="cursor-pointer">View offers</span>
                          </a>
                        </div>
                      ) : null
                    }
                  </div>
                  <div data-testid="#add-to-cart" className="new-add-to-cart counter-wrap">
                    <AddToCartButton
                      isSoldOut={isSoldOut}
                      handleProductClick={handleProductClick}
                      productCounterType={productCounterType}
                      productIndex={productIndex}
                      categoryIndex={categoryIndex}
                      data={data}
                      setShowLoader={setShowLoader}
                      showLoader={showLoader}
                    />
                    {process.browser && children}
                  </div>
                  {isSoldOut || limitExceeded ? (
                    <div className="product-soldout-limit">{isSoldOut ? 'Sold Out' : 'Limit Exceeded'}</div>
                  ) : null}
                </div>
              </div>
            </div>
            <CSSTransition in={showAnimation} mountOnEnter timeout={300} classNames="indicator" unmountOnExit>
              <div className="indicator">
                <div className="ring" />
                <div className="text">Tap to View</div>
                <i className="icon-click" />
              </div>
            </CSSTransition>
          </>
        </div>
        <>
          {addingToCart ? (
            <Modal>
              <TransparentLoader fixed />
            </Modal>
          ) : null}
        </>
      </>
    </div>
  );
});

export default ProductSkeleton;
